import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import CallToAction from '../../components/home/CallToAction';

const features = [
  {
    title: 'Platform Integration',
    description: 'Seamless integration with major email marketing platforms and CRM systems.',
  },
  {
    title: 'Automated Workflows',
    description: 'Design intelligent email campaigns that respond automatically to customer actions and triggers.',
  },
  {
    title: 'Data Synchronization',
    description: 'Real-time synchronization of customer data and campaign metrics.',
  },
  {
    title: 'Campaign Analytics',
    description: 'Comprehensive reporting and analytics for campaign performance.',
  },
];

const EmailMarketing = () => {
  const handleClick = () => {
    window.location.href = '/index2.html';
  };

  return (
    <>
      <Box
        className="gradient-bg"
        sx={{
          py: 8,
          color: 'white',
          textAlign: 'center',
        }}
      >
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h2"
              component="h1"
              sx={{ mb: 2, fontWeight: 700 }}
            >
              Email Marketing Integration
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mb: 4,
                maxWidth: '800px',
                mx: 'auto',
                opacity: 0.9,
              }}
            >
              Seamlessly integrate your email marketing platforms with your business systems
            </Typography>
          </motion.div>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography variant="h4" gutterBottom>
                Enhance Your Email Marketing
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                paragraph
                sx={{ mb: 4 }}
              >
                Our email marketing integration service unifies your email platforms with existing business systems to streamline campaign management.
              </Typography>
              <Typography variant="h4" gutterBottom>
                Why Choose Our Integration?
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                paragraph
              >
                We leverage our expertise to automate your email campaigns, ensure real-time data synchronization, and deliver actionable performance metrics.
              </Typography>
              <Typography variant="h4" gutterBottom>
                Free report 
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                paragraph
              >
                Sign up to receive a free report that will give you an extensive list of email marketing tools  
                <br />
                <button 
                  className="ml-button"
                  onClick={handleClick}
                  style={{
                    padding: '10px 20px',
                    cursor: 'pointer',
                    backgroundColor: '#007bff',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    marginTop: '15px',
                    fontSize: '16px',
                    fontWeight: '500',
                    transition: 'background-color 0.3s ease'
                  }}
                >
                  Get Your Free Report
                </button>
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              {features.map((feature, index) => (
                <Grid item xs={12} sm={6} key={feature.title}>
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: index * 0.2 }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        p: 3,
                        height: '100%',
                        bgcolor: 'background.light',
                        borderRadius: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        {feature.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {feature.description}
                      </Typography>
                    </Paper>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <CallToAction />
    </>
  );
};

export default EmailMarketing;